/* Timeline */
/*--- Vertical --*/

.vtimeline {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: 20px;

  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #d1d4e4;
    left: 50%;
    margin-left: -1.5px;
  }

  .timeline-wrapper {
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding-right: 90px;

    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    .timeline-panel {
		border-radius: 2px;
		padding: 20px;
		position: relative;
		background: $gray-100;
		border-radius: 0px;
		box-shadow: none;
		width: 35%;
		margin-left: 15%;

      &:before {
        position: absolute;
        top: 0;
        width: 100%;
        height: 2px;
        content: "";
        left: 0;
        right: 0;
      }

      &:after {
        position: absolute;
		top: 10px;
		right: -14px;
		display: inline-block;
		border-top: 14px solid transparent;
		border-left: 14px solid $gray-100;
		border-right: 0 solid $gray-100;
		border-bottom: 14px solid transparent;
		content: " ";
      }

      .timeline-title {
        font-size: 1rem;
        font-weight: 700;
        margin: 0 0 .625rem;
      }

      .timeline-body p + p {
        margin-top: 5px;
      }
    }
  }
}

.timeline-body img {
  max-width: 100%;
}

.vtimeline .timeline-wrapper {
  .timeline-panel {
    .timeline-body ul {
      margin-bottom: 0;
    }

    .timeline-footer span {
      font-size: .6875rem;
    }
  }

  .timeline-badge {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 16px;
    left: 48.5%;
    z-index: 0;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border: 2px solid $white;
    border-radius: 50%;

    i {
        color: $white;
		text-align: center;
		font-size: 25px;
		line-height: 43px;
		margin: 0 auto;
		justify-content: center;
		left: 0;
		right: 0;
		padding: 1px 9px;
    }
  }

  &.timeline-inverted {
    padding-right: 0;
    padding-left: 90px;

    .timeline-panel {
      margin-left: auto;
      margin-right: 15%;

      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }
  }
}

.timeline-wrapper-primary {
  .timeline-panel:before, .timeline-badge {
    background: $primary;
  }
}

.timeline-wrapper-secondary {
  .timeline-panel:before, .timeline-badge {
    background: #eb6f33;
  }
}

.timeline-wrapper-success {
  .timeline-panel:before, .timeline-badge {
    background: $success;
  }
}

.timeline-wrapper-info {
  .timeline-panel:before, .timeline-badge {
    background: #01b8ff;
  }
}

.timeline-wrapper-warning {
  .timeline-panel:before, .timeline-badge {
    background: $warning;
  }
}

.timeline-wrapper-danger {
  .timeline-panel:before, .timeline-badge {
    background: $danger;
  }
}

.timeline-wrapper-light {
  .timeline-panel:before, .timeline-badge {
    background: #f1f2f9;
  }
}

.timeline-wrapper-dark {
  .timeline-panel:before, .timeline-badge {
    background: $gray-900;
  }
}

@media (max-width: 767px) {
  .timeline .btn {
    font-size: 12px;
  }

  .vtimeline {
    &:before {
      left: 26px !important;
    }

    .timeline-wrapper {
      .timeline-badge {
        left: 0 !important;
      }

      padding-right: 0 !important;

      .timeline-panel {
        width: auto !important;
        margin-left: 16% !important;

        &:after {
          border-right: 14px solid $gray-100 !important;
          border-left: 0 solid $gray-100 !important;
          left: -14px !important;
          right: auto !important;
        }
      }

      &.timeline-inverted {
        padding-left: 0 !important;

        .timeline-panel {
          margin-right: 0 !important;
          width: auto !important;
        }
      }
    }
  }

  .profile-user .p-text {
    display: none;
  }
}

/*latetes news timeline */

.latest-timeline-1 ul.timeline-1 {
  list-style-type: none;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    border-left: 2px dotted #c0ccda;
  }

  > li {
    a {
      color: $black;
    }

    .date {
      font-size: 10px;
    }

    p {
      font-size: 13px;
    }

    margin: 25px 0;
    padding-left: 0.90rem;

    .product-icon {
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: 1px;
      background-color: #f3f6f7;
      font-size: 20px;
      line-height: 37px;
      font-size: 21px;
    }

    &:nth-child(2):before {
      border: 3px solid #fb1c52;
    }

    &:nth-child(3):before {
      border: 3px solid #e9b307;
    }

    &:nth-child(4):before {
      border: 3px solid #4481eb;
    }

    &:last-child:before {
      border: 3px solid #27af06;
    }
  }
}

.projects-stat {
  .table-bordered {
    th, td {
      border: transparent;
    }
  }

  .table {
    th, td {
      padding: 7px 7px !important;
      vertical-align: baseline;
    }
  }

  .project-names h6 {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: $white;
    line-height: 2.5;
    margin-bottom: 0;
    font-weight: 600;
    padding: 3px;
  }
}

.review-project {
  .project-contain {
    display: inline-block;
  }

  .table {
    th, td {
      vertical-align: middle;
      padding: 7px 15px;
    }
  }

  &.card tbody > tr > {
    th:not(:first-child), td:not(:first-child) {
      font-family: roboto;
      font-size: 13px !important;
    }
  }

  img {
    vertical-align: top;
    width: 35px;
    min-width: 32px;
    height: 35px;
  }
}

.latest-tasks {
  .tasks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    margin: 0 0 1.15rem 0;

    .span {
      margin-left: 1.3rem;
      font-weight: 500;
      color: $gray-900;
    }
  }

  .task-line:before {
    position: absolute;
    display: block;
    width: .2rem;
    top: .2rem;
    content: "";
    border-radius: .2rem;
    height: 2rem;
  }

  .label {
    color: $default-color;
    margin-left: 1.3rem;
    font-weight: 500;
  }

  .time {
    margin-left: 1.3rem;
    font-size: 13px;
    color: $gray-600;
  }

  .task-line {
    &.primary:before {
      background: $primary;
    }

    &.pink:before {
      background: $pink;
    }

    &.success:before {
      background: $success;
    }

    &.teal:before {
      background: $teal;
    }

    &.warning:before {
      background: $warning;
    }

    &.orange:before {
      background: $orange;
    }

    &.info:before {
      background: $info;
    }

    &.purple:before {
      background: $purple;
    }

    &.danger:before {
      background: $danger;
    }
  }

  .check-box .ckbox span {
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      background-color: rgba(190, 206, 255, 0.05);
      border: 1px solid #d9e2ff;
      top: 1px;
      left: 0;
      border-radius: 2px;
    }

    &:after {
      border-radius: 2px;
    }
  }

  .nav-tabs .nav-link {
    &.active, &:hover, &:focus {
      color: $primary;
      border:0;
    }

    color: $gray-600;
  }
}

.image-grouped img {
  margin-right: -.6em !important;
}

.background-text {
  position: relative;
  z-index: 10;
}

.project-countdown {
  #launch_date {
    margin: 0;
  }

  .countdown {
    padding: 0;
    margin: 0;

    li {
      width: 65px;
      height: 65px;
      z-index: 0;
      padding: 0px;
      line-height: 30px;
    }

    .number {
      font-size: 20px;
      font-weight: 700;
      line-height: 14px;
      padding-top: 11px;
    }

    .time {
      padding-top: 0px;
      font-size: 11px !important;
    }

    span {
      display: block;
      color: $white;
    }

    li {
      background: rgb(40, 92, 247);
      border: 5px solid rgb(40, 92, 247);
    }
  }
}

@media (min-width: 992px) {
  .project-countdown .countdown li {
    margin: 2px 5px 7px 5px;
  }
}

.Activity-scroll {
  max-height: 349px;
}

.activity .added-project {
  font-weight: 700;
  font-size: 12px;
  color: $primary;
}

.sparkline .tooltip {
  width: 20px;
  height: 20px;
}

.Project-scroll {
  max-height: 367px;
  position: relative;
}

.rating-scroll {
  max-height: 344px;
  position: relative;
}

.btn-sm, .btn-group-sm > .btn {
  padding: .2rem .7rem;
  font-size: .7rem;
}

.coming-events {
  .icon {
    height: 47px;
    width: 47px;
    border-radius: 50%;
    text-align: center;

    span {
      display: block;
      font-size: 12px;
    }

    .date {
      line-height: 10px;
      padding-top: 11px;
    }

    .month {
      font-size: 11px;
    }
  }

  .pb-3 {
    padding-bottom: 0.8rem !important;
  }

  .pt-3 {
    padding-top: 0.8rem !important;
  }
}

.activity {
  position: relative;
  padding: 1.25rem 1.25rem 0 1.25rem;
}

.activity-list {
  position: relative;

  &:before {
    content: ' ';
    border: 1px solid #eaf2f9;
    margin: 20px 20px 0 22px;
    display: inline-block;
    position: absolute;
    width: 2px;
    height: 100%;
    z-index: 0;
  }
}

.activity {
  .img-activity {
    width: 42px;
    height: 42px;
    text-align: center;
    line-height: 34px;
    border-radius: 50%;
    position: absolute;
    -webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
    box-shadow: 0 0 0 0.5px #f8f9fa;
  }

  .item-activity {
    margin-left: 60px;
    margin-bottom: 22px;
  }
}
.timeline-image{
  border-radius:50%;
}
@media (max-width: 576px){
	.vtimeline .timeline-wrapper .timeline-panel {
		width: auto !important;
		margin-left: 24% !important;
	}
}