/* ###### 4.12 Datetimepicker ###### */

/* Amaze UI Datetimepicker */

.datetimepicker {
  box-shadow: none !important;
  border-radius: 0 !important;
  border-width: 1px !important;
  border-color: $gray-300 !important;
  min-width: 270px !important;
  padding: 0 10px 10px !important;

  &::before {
    display: none !important;
  }

  table {
    width: 100% !important;

    th {
      &.prev, &.next, &.switch {
        background-color: $white !important;
        color: $default-color !important;
      }

      &.prev, &.next {
        position: relative !important;
      }

      &.prev span, &.next span {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
      }

      &.prev span::before, &.next span::before {
        font-family: 'Ionicons' !important;
        position: absolute !important;
        top: 50% !important;
        font-size: 16px !important;
        color: $gray-900 !important;
        line-height: 0 !important;
      }

      &.prev {
        &:hover, &:focus {
          background-color: $white !important;
        }
      }

      &.next {
        &:hover, &:focus {
          background-color: $white !important;
        }
      }

      &.prev {
        &:hover span::before, &:focus span::before {
          color: $primary !important;
        }
      }

      &.next {
        &:hover span::before, &:focus span::before {
          color: $primary !important;
        }
      }

      &.prev span::before {
        content: '\f3cf' !important;
        left: 0 !important;
      }

      &.next span::before {
        content: '\f3d1' !important;
        right: 0 !important;
      }

      &.switch {
        text-transform: uppercase !important;
        font-weight: 700 !important;
        font-size: 13px !important;
        letter-spacing: 1px !important;
        color: $gray-900 !important;

        &:hover, &:focus {
          background-color: $white !important;
          color: $primary !important;
        }
      }
    }

    td {
      font-size: 0.875rem !important;
      text-align: center !important;

      &.old, &.new {
        color: $gray-500 !important;
      }

      &.active {
        background-color: $primary !important;
        color: $white !important;

        &:hover, &:focus {
          background-color: $primary !important;
          color: $white !important;
        }
      }
    }

    span.active {
      background-color: $primary !important;
      color: $white !important;

      &:hover, &:focus {
        background-color: $primary !important;
        color: $white !important;
      }
    }
  }

  .datetimepicker-days table thead tr {
    &:first-child th {
      height: 38px !important;
    }

    &:last-child th {
      height: 25px !important;
      text-transform: uppercase !important;
      color: $gray-700 !important;
      font-weight: 700 !important;
      font-size: 11px !important;
    }
  }
}

/* jQuery Simple Datetimepicker */

.main-datetimepicker {
  box-shadow: none !important;
  border-radius: 0 !important;
  border-color: $gray-300 !important;
  margin-top: 2px !important;

  > {
    .datepicker_header {
      padding: 10px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;

      > .icon-home {
        display: none !important;
      }

      a {
        &:nth-child(2), &:last-child {
          display: block !important;
          position: relative !important;
          text-indent: -999999px !important;
        }

        &:nth-child(2)::before, &:last-child::before {
          font-family: 'Ionicons' !important;
          position: absolute !important;
          top: 50%;
          line-height: 0 !important;
          text-indent: 0 !important;
          font-size: 13px !important;
        }

        &:nth-child(2)::before {
          content: '\f3cf' !important;
          left: 0;
        }

        &:last-child::before {
          content: '\f3d1' !important;
          right: 0;
        }
      }

      span {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        text-transform: uppercase !important;
      }
    }

    .datepicker_inner_container {
      border-radius: 0 !important;
      box-shadow: none !important;
      border-width: 0 !important;
      display: flex !important;
      height: 225px !important;
      margin: 0 !important;
      background-color: $white !important;

      &::after {
        display: none !important;
      }

      > {
        .datepicker_calendar {
          width: 230px !important;
          border-width: 0 !important;
          border-radius: 0 !important;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          padding: 5px !important;
          float: none !important;
          margin: 0 !important;

          > table {
            width: 100% !important;
            height: 100% !important;
          }

          th {
            font-size: 11px !important;
            text-transform: uppercase !important;
            color: $gray-900 !important;
            font-weight: 700 !important;
            text-align: center !important;
            padding: 8px 5px !important;
          }

          td {
            font-size: 0.875rem !important;
            font-weight: 400 !important;
            text-align: center !important;
            padding: 5px !important;

            &.day_another_month {
              color: $gray-500 !important;
            }

            &.hover {
              background-color: $gray-200 !important;
              cursor: pointer !important;
            }

            &.active {
              background-color: $primary !important;
              color: $white !important;
			  border-radius: 2px !important;
            }
          }
        }

        .datepicker_timelist {
          float: none !important;
          width: 70px !important;
          height: 100% !important;
          border-left: 1px solid$gray-400 !important;

          > div.timelist_item {
            padding: 5px 8px !important;
            margin: 0 0 0 1px !important;

            &:hover, &:focus {
              background-color: $gray-200 !important;
            }

            &.active {
              background-color: $primary !important;
              color: $white !important;
            }
          }
        }
      }
    }
  }
}

/* Picker JS */

.picker-title {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.picker-cell__header {
  text-transform: uppercase !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  letter-spacing: .4px !important;
}

.picker-item {
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.picker-picked {
  font-weight: 500 !important;
  color: $primary !important;
}